import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import Vue from '@fwk-node-modules/vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface StockAdminInput {
}

export function useStockAdmin(props:StockAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);
  var stockProducts:Ref<any[]> = ref([]);

  const updateList = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/stock/list');
      stockProducts.value = response.products ? response.products : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateList();

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateList();
    },
    { deep: false }
  )

  const stockForm:any = reactive({
    productID : null,
    isStockActivated: false,
    currentStock : '',
    availableStock : '',
    comingAction: 0 // The coming action is the difference between available stock and current stock (before any update)
  });

  const updateFormFromProduct = (product:any) => {
    stockForm.productID = product._id;
    stockForm.currentStock = (product.stock && product.stock.currentStock !== "" && !isNaN(product.stock.currentStock)) ? product.stock.currentStock : '';
    stockForm.availableStock = (product.stock && product.stock.availableStock !== "" && !isNaN(product.stock.availableStock)) ? product.stock.availableStock : '';
    stockForm.isStockActivated = stockForm.currentStock !== '';
    stockForm.comingAction = (stockForm.isStockActivated) ? parseInt(stockForm.availableStock) - parseInt(stockForm.currentStock) : 0;
  };

  watch(
    () => stockForm.currentStock,
    (val: any, oldVal: any) => {
      if(stockForm.currentStock !== '' && !isNaN(stockForm.currentStock)) {
        // We keep the available stock up to date
        stockForm.availableStock = parseInt(stockForm.currentStock) + stockForm.comingAction;
      }
      else {
        stockForm.availableStock = ''
      }
    },
    { deep: true }
  )

  const updateStock = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...stockForm
    }

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/stock/update', input);
      if(response.updated) {  
        var product = response.product;
        // We update the form
        updateFormFromProduct(product);

        result.updated = true;
        result.product = response.product;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    stockProducts,
    updateList,
    updateFormFromProduct,
    stockForm,
    updateStock
  }
  
}