<template>
  
    <form role="form" @submit="onFormSubmit" name="update-stock-product">
      
      <div class="form-group row">
        <div class="col-lg-3 col-form-label">{{$t('shop.info.product.title')}}</div>
        <div class="col-lg-9">
          <div class="form-control-plaintext">{{ product.title }}</div>
        </div>
      </div>

      <div class="form-group row">
        <label for="updateActivateStock" class="col-lg-3 col-form-label">{{$t('shop.info.stock.activate')}}</label>
        <div class="col-lg-9">
          <p-check id="updateActivateStock" class="p-switch p-fill" color="success" v-model="stockForm.isStockActivated">&nbsp;</p-check>{{$t('shop.info.stock.activate_checkbox')}}
        </div>
      </div>

      <div class="form-group row">
        <label for="updateCurrentStock" class="col-lg-3 col-form-label">{{$t('shop.info.stock.currentStock')}}</label>
        <div class="col-lg-9">
          <div>
            <input id="updateCurrentStock" type="text" :disabled="!stockForm.isStockActivated" :placeholder="$t('shop.info.stock.currentStock_placeholder')" class="form-control" v-model="stockForm.currentStock" />
            <span id="passwordHelpBlock" class="form-text text-muted">
              {{ $t('shop.info.stock.currentStock_note') }}
            </span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="updateAvailableStock" class="col-lg-3 col-form-label">{{$t('shop.info.stock.availableStock')}}</label>
        <div class="col-lg-9">
          <div>
            <input id="updateAvailableStock" type="text" :disabled="true" :placeholder="$t('shop.info.stock.availableStock_placeholder')" class="form-control" v-model="stockForm.availableStock" />
            <span id="passwordHelpBlock" class="form-text text-muted">
              {{ $t('shop.info.stock.availableStock_note') }}
            </span>
          </div>
        </div>
      </div>

      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('shop.update.button')}}</button>
    </form>

</template>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useShopAdmin } from '../../../composables/useShopAdmin';
import { useStockAdmin } from '../../../composables/useStockAdmin';

export default defineComponent({
  props: {
      product: {
        type: Object,
        required: true
      }
  },
  components: {
      
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectedShop } = useShopAdmin(props, context);
    const { stockProducts, updateFormFromProduct, updateStock, stockForm } = useStockAdmin(props, context);
    
    const { product } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-stock-product] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    updateFormFromProduct(product.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateStock().then((result:any) => {
        if(result.updated) {
          context.emit('stock-updated', result.product);
        }
        laddaSubmit!.stop();
      })
    }

    // We watch if we have site ID in URL
    watch(
      product,
      (val:any, oldVal:any) => {
        updateFormFromProduct(product.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      stockForm,
      product
    }
  }
})

</script>