<template>
  <div class="ibox stock-products">
    <div class="ibox-title">
      <h2>{{ $t('shop.stock.stock-products.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="stockProducts"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>       
        <template v-slot:cell(title)="row">
          {{row.item.title}}
          <span v-if="row.item.subtitle && row.item.subtitle!=''">
            <br/>{{row.item.subtitle}}
          </span>
        </template>  
        <template v-slot:cell(options)="row">
          <span>
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.stock.stock-products.options.update')}}</a>
          </span>
        </template>
      </b-table>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.stock.stock-products.update.title', {productID: itemToUpdate._id})" hide-footer lazy>
        <UpdateStockProduct :product="itemToUpdate" v-on:stock-updated="onItemUpdated" />
      </b-modal>

    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateStockProduct from './Update.vue';
import { languagesTypes } from '@fwk-client/store/types';

import { useShopAdmin } from '../../../composables/useShopAdmin';
import { useStockAdmin } from '../../../composables/useStockAdmin';

export default defineComponent({
  props: {
  },
  components: {
    UpdateStockProduct
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectedShop } = useShopAdmin(props, context);
    const { stockProducts, updateList } = useStockAdmin(props, context); 

    const listItems:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return stockProducts.value.length
    })

    const itemToUpdate:Ref<any> = ref({});

    
    const listLoading:Ref<boolean> = ref(false);

    const listItemsSelected:Ref<any[]> = ref([]);

    const productTypeFormatter = () => {
      return (value:any, key:any, item:any) => {
        return app.$t('shop.products.products.types.'+value) as string;
      }
    }

    const stockFormatter = () => {
      return (value:any, key:any, item:any) => {
        if(value == undefined) {
          // The stock is available
          return app.$t('shop.stock.stock-products.stock-available') as string;
        }
        return value
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "type",
        label: "",
        formatter: productTypeFormatter()
      },
      /*
      {
        key: "reference",
        label: ""
      },
      */
      {
        key: "title",
        label: "",
      },
      {
        key: "stock.currentStock",
        label: "",
        formatter: stockFormatter()
      },
      {
        key: "stock.availableStock",
        label: "",
        formatter: stockFormatter()
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.stock.stock-products.headers.type') as string;
      // listFields[2].label = this.$t('shop.stock.stock-products.headers.reference') as string;
      listFields[2].label = app.$t('shop.stock.stock-products.headers.title') as string;
      listFields[3].label = app.$t('shop.stock.stock-products.headers.currentStock') as string;
      listFields[4].label = app.$t('shop.stock.stock-products.headers.availableStock') as string;
      listFields[5].label = app.$t('shop.products.products.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemUpdated = (item:any) => {
      // We update the item
      itemToUpdate.value = item;
      updateList();    
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    return {
      stockProducts,
      listLoading,
      showNumberOfRows,
      numberOfRows,
      shops,
      listItems,
      listFields,
      listItemsSelected,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,
      showUpdateModal
    }
  }
})
</script>